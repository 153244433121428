
import data from "~/data/homePage.js";
import Slider from "./slider.vue";
export default {
  data() {
    return {
      items: data.customers,
      selectedItem: "mali",
      t: 0,
    };
  },
  methods: {
    setItem(index) {
      this.selectedItem = index.name;
      this.$emit("change", index.name);
    },
  },
  components: { Slider },
};
