
import Slider from "./slider.vue";
import Categories from "./categories.vue";
export default {
  components: { Slider, Categories },
  data() {
    return {
      category: "mali",
    };
  },
  methods: {
    changeCategory(payload) {
      this.category = payload;
    },
  },
};
