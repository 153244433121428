
export default {
  props: ["label", "value"],
  data() {
    return {
      slideValue: this.value
    }
  },
  watch: {
    slideValue() {
      this.$emit('input', this.slideValue)
    }
  },
};
