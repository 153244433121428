// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/svg/slider-bg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root[data-v-e2fba3dc]{align-items:center;direction:rtl;display:flex;width:100%}#root .label[data-v-e2fba3dc]{width:8%}#root .slider[data-v-e2fba3dc]{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:#fafcff;border-radius:25px;box-shadow:0 4px 4px rgba(159,230,255,.13);direction:ltr;height:20px;outline:none;transform:matrix(-1,0,0,1,0,0);width:100%}#root .slider[data-v-e2fba3dc]:focus::-moz-range-thumb,#root .slider[data-v-e2fba3dc]:focus::-webkit-slider-thumb{animation:3s infinite;animation-name:slider-thumb-animation-e2fba3dc;background:#3af3fe}#root .slider[data-v-e2fba3dc]::-moz-range-thumb,#root .slider[data-v-e2fba3dc]::-webkit-slider-thumb{-webkit-appearance:none;appearance:none;background:#ffb500;border:1em solid #fff;border-radius:50%;cursor:pointer;height:19px;outline:12px solid hsla(0,0%,100%,.5);position:relative;width:19px}#root .slider[data-v-e2fba3dc]::-moz-range-progress{background:linear-gradient(90deg,#0081ea .01%,#02f0fe 53.43%),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat-x;border-radius:25px;height:100%}@keyframes slider-thumb-animation-e2fba3dc{25%{outline:12px solid hsla(0,0%,100%,.5)}50%{outline:12px solid hsla(0,0%,100%,.2)}80%{outline:2px solid hsla(0,0%,100%,.5)}to{outline:12px solid hsla(0,0%,100%,.5)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
