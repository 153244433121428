
import data from "~/data/homePage.js";
export default {
  props: ["category"],
  computed: {
    sliderItem() {
      return this.items.filter((x) => x.name == this.category)[0].children[
        this.showingIndex
      ];
    },
    sliderItems() {
      return this.items.filter((x) => x.name == this.category)[0].children;
    },
  },
  data() {
    return {
      items: data.customers,
      showingIndex: 0,
      readingMore: false,
    };
  },
  watch: {
    category() {
      this.showingIndex = 0;
      this.readingMore = false;
    },
    showingIndex() {
      this.readingMore = false;
    },
  },
  methods: {
    handleClickCircle(index) {
      this.showingIndex = index;
    },
    nextSlide() {
      if (this.canGoNext()) this.showingIndex++;
    },
    prevSlide() {
      if (this.canGoPrev()) this.showingIndex--;
    },
    canGoNext() {
      return this.showingIndex < this.sliderItems.length - 1;
    },
    canGoPrev() {
      return this.showingIndex > 0;
    },
    readMore() {
      this.readingMore = true;
    },
  },
};
